<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{ name: 'home' }" class="root">
                Dashboard
            </router-link>
            <span class="child">  / Member Issued Book </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center" style="margin:10px;"> Issued Book</v-card-title>
            <v-card-text v-for="item in booksList" :key='item'>
                <div v-if="item.isactive">

                    <v-card style="background-color: #4CBB17;">

                        <v-card-text>
                            <v-row style="color: white;">
                                <v-col  sm="5" md="5" lg="5">
                                    <b>Book Accession No. : </b> {{ item.accession_number }}<br>
                                    <b>Title : </b> {{ item.title }}<br>
                                    <b>Authors : </b> <span v-for="name in item.author" :key='name.auth_name'>{{
                                        name.auth_names }}</span><br>
                                    <b>Category : </b> {{ item.bookcategory }}<br>
                                    <b>Book Price : </b> {{ item.price }}<br>
                                    <span><b>Borrowed Date : </b> {{ item.display_borrowing_date }}</span>
                                    <span v-if="item.fine_amount != 0"><b>Fine Amount : </b> {{ item.fine_amount }}</span>
                                </v-col>
                                <v-col sm="5" md="5" lg="5">
                                    <b>Publisher : </b> {{ item.publisher }}<br>
                                    <b>Book Type.</b> {{ item.booktype }}<br>
                                    <b>Book Format : </b> {{ item.bookformat }}<br>
                                    <b>No. of Copy Avaialable</b> -<br>
                                    <b>No. of Pages : </b> {{ item.numberofpages }}<br>
                                    <span><b>Due Date : </b> {{ item.displey_due_date }}</span>
                                </v-col>
                                <v-col sm="2" md="2" lg="2">
                                    <b>Book QR Code : </b> <br><vue-qrcode :value="item.accession_number" />
                                </v-col>
                            </v-row>

                         
                        </v-card-text>
                    </v-card>

                </div>
                <div v-else>

                    <v-card >

                        <v-card-text>
                            <v-row>
                                <v-col sm="5" md="5" lg="5">
                                    <b>Book Accession No. : </b> {{ item.accession_number }}<br>
                                    <b>Title : </b> {{ item.title }}<br>
                                    <b>Authors : </b> <span v-for="name in item.author" :key='name.auth_name'>{{
                                        name.auth_names }}</span><br>
                                    <b>Category : </b> {{ item.bookcategory }}<br>
                                    <b>Book Price : </b> {{ item.price }}<br>
                                    <span><b>Borrowed Date : </b> {{ item.display_borrowing_date }}</span>
                                    <span v-if="item.fine_amount != 0"><b>Fine Amount : </b> {{ item.fine_amount }}</span>
                                </v-col>
                                <v-col sm="5" md="5" lg="5">
                                    <b>Publisher : </b> {{ item.publisher }}<br>
                                    <b>Book Type.</b> {{ item.booktype }}<br>
                                    <b>Book Format : </b> {{ item.bookformat }}<br>
                                    <b>No. of Copy Avaialable</b> -<br>
                                    <b>No. of Pages : </b> {{ item.numberofpages }}<br>
                                    <span><b>Due Date : </b> {{ item.displey_due_date }}</span>
                                </v-col>
                                <v-col sm="2" md="2" lg="2">
                                    <b>Book Bar Code : </b> <br><vue-qrcode :value="item.accession_number" />
                                </v-col>
                            </v-row>

                        
                        </v-card-text>
                    </v-card>

                </div>
                
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            Loading please Wait.
        </v-overlay>
    </div>
</template>

<script>
import axios from "axios";
import VueQrcode from 'vue-qrcode'
//import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        booksList: null,
    }),
    components: {
        VueQrcode,

    },

    mounted() {
        this.onLoad();

    },
    methods: {
        onLoad() {
                this.overlay = true;
                axios
                    .get("/MemberProcess/getMemberIssuedBook")
                    .then((res) => {
                        if (res.data.msg == "200") {
                             this.overlay = false;
                            this.booksList = res.data.booksList;

                        }
                        else{
                            this.showSnackbar("red", res.data.msg); // show snackbar
this.overlay = false;
                        }
                    })
                 
            },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },


    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}

.cus-card {
    background: #d3d3d33d;
    margin: 0rem 0.5rem 0.5rem 0.5rem;
    padding: 1rem;
    border: 2px solid gray;
}

.c-label {
    font-weight: bold;
}
</style>
